import { css, FlattenSimpleInterpolation } from 'styled-components';

export const button = {
  border: {
    radius: '4px',
  },
  gap: '12px',
  size: {
    small: {
      border: {
        radius: '3px',
      },
      pad: {
        horizontal: '11px',
        vertical: '7px',
      }
    },
    medium: {
      border: {
        radius: '4px',
      },
      pad: {
        horizontal: '14px',
        vertical: '12px',
      },
    },
    large: {
      border: {
        radius: '5px',
      },
      pad: {
        horizontal: '16px',
        vertical: '17px',
      },
    },
  },
  default: {
    color: 'white',
    font: {
      weight: 500,
    }
  },
  primary: {
    color: 'white',
    background: {
      color: 'accent-1',
    },
    font: {
      weight: 500,
    }
  },
  secondary: {
    color: 'white',
    background: {
      color: 'grey-6',
    },
    font: {
      weight: 500
    }
  },
  hover: {
    // TODO: Can we make this fade out too?
    extend: (): FlattenSimpleInterpolation => css`
      transition: 0.5s ease;
      filter: brightness(0.85);
    `,
  },
};
