import { gql } from '@apollo/client';
import { PartnerThemeProvider } from '@lwe/toolkit/theming';

export const CLASSROOM_CONFIGURATION_FEATURES_QUERY = gql`
  query ClassroomConfigurationFeaturesQuery {
    configuration {
      id
      slug
      domain {
        id
        url
        hostname
      }
      features {
        id
        shop: feature(name: "shop")
        cpd: feature(name: "cpd")
        requiresAvatarCapture: feature(name: "avatar.capture.required")
        signup: feature(name: "signup")
        signupWithFacebook: feature(name: "signup_facebook")
        lessonSurveys: feature(name: "lesson_surveys")
        showLweCredit: feature(name: "show_lwe_credit")
        studentInvitationMessages: feature(name: "student_invitation_messages")
        rhsReport: feature(name: "rhs_report")
      }
      settings {
        productTourWorkspaceCode: setting(key: "product_tour.workspace_code")
        homepageRedirect: setting(key: "homepage.redirect")
        parentUrl: setting(key: "parent.url")
        muxDataEnvKey: setting(key: "mux.data.env.key")
        gtmContainerId: setting(key: "gtm.container.id")
        gtmContainerParams: setting(key: "gtm.container.params")
        helpscoutAppId: setting(key: "helpscout.app.id")
      }
      ...Configuration__partner
    }
  }
  ${PartnerThemeProvider.fragments.partner}
`;
